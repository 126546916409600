import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['password', 'passwordConfirmation', 'passwordConfirmationInput', 'pwLength', 'pwSymbol', 'pwUpperCase', 'pwLowerCase', 'submit']

    connect () {
        this.checkPassword()

        document.querySelectorAll('input').forEach((node) => {
            const eventListener = (e) => this.checkLabelStatus(e.currentTarget)
            node.addEventListener('focus', eventListener)
            node.addEventListener('blur', eventListener)
            node.addEventListener('change', eventListener)
            this.checkLabelStatus(node)
        })
    }

    checkPassword () {
        const allValid = [
            [/^.{8,}$/, this.password, this.pwLengthTarget],
            [/[a-z]+/, this.password, this.pwLowerCaseTarget],
            [/[A-Z]+/, this.password, this.pwUpperCaseTarget],
            [/([0-9]|[^0-9a-zA-Z\s])+/, this.password, this.pwSymbolTarget]
        ].map(([regEx, value, field]) => {
            field.classList.remove('invalid')
            field.classList.remove('valid')
            if (value) {
                const valid = regEx.test(value)
                if (valid) {
                    field.classList.add('valid')
                } else {
                    field.classList.add('invalid')
                }
                return valid
            }
            return false
        }).reduce((ret, item) => ret && item, true)

        const passwordConfirmed = this.password === this.passwordConfirmation
        if (passwordConfirmed) {
            this.passwordConfirmationTarget.classList.remove('invalid')
        } else if (this.passwordConfirmation) {
            this.passwordConfirmationTarget.classList.add('invalid')
        }

        this.submitTarget.disabled = !(allValid && passwordConfirmed)
    }

    get password () {
        return this.passwordTarget.value
    }

    get passwordConfirmation () {
        return this.passwordConfirmationInputTarget.value
    }

    checkLabelStatus = (input) => {
        const label = document.querySelector(`label[for="${input.id}"]`)
        if (label) {
            const isFocused = input === document.activeElement
            const hasValue = input.value.length > 0
            if (isFocused || hasValue) {
                label.classList.add('active')
            } else {
                label.classList.remove('active')
            }
        }
    }
}
